$fa-font-path: "//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts";
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import "lumen/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "lumen/bootswatch";
@import "node_modules/font-awesome/scss/font-awesome";
@import "https://cdnjs.cloudflare.com/ajax/libs/sweetalert/1.1.3/sweetalert.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.6.4/css/bootstrap-datepicker3.standalone.min.css";


.nomargins {
  margin: 0px;
}